import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import App from './App';
import { startFontLoadListener } from './js/fontLoad';
import { startAnalytics } from './js/analytics';
import reportWebVitals from './js/reportWebVitals';

startAnalytics();
startFontLoadListener();

ReactDOM.createRoot(document.getElementsByTagName('body')[0]).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
